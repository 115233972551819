@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

.container {
  @apply max-w-[1400px] m-auto px-5 2xl:px-0;
}

.vhcenter {
  @apply flex justify-center items-center;
}

.home-topbg {
  background: url('/assets/images/shiplyfinal.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.overlay {
  @apply bg-secondary bg-opacity-80 w-full h-full absolute top-0 left-0;
}

.booking-bg {
  background: url('/assets/images/cars-city.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 800px;
  position: relative;
}

.homebanner-bg {
  background: url('/assets/images/homebanner.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 200px;
  position: relative;
}

.topbanner-bg {
  background: url('/assets/images/about.png') no-repeat center center;
  @apply h-[200px] md:min-h-[400px];
  background-size: cover;
  width: 100%;
  position: relative;
}

.aboutbanner-bg {
  background: url('/assets/images/aboutbanner.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 200px;
  position: relative;
}

.aboutservice-bg {
  background: url('/assets/images/aboutservice.png') no-repeat bottom center;
  background-size: cover;
  width: 100%;
  min-height: 600px;
  position: relative;
}

.aboutservice-overlay {
  @apply bg-[#8e969c] bg-opacity-80 w-full h-full absolute top-0 left-0;
}

.testimonials {
  background: url('/assets/images/testimonials.png') no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 600px;
  position: relative;
}

.react-calendar {
  background: #212529;
  color: white;
  border: none;
  font-size: 16px;
  padding: 20px 15px;
  width: 100%;
  max-width: 100%;
  width: 100%;
  height: 450px;
}
.react-calendar__navigation__label__labelText {
  padding: 20px;
}
.react-calendar__navigation {
  padding: 10px;
  text-align: center;
}
.react-calendar__navigation__arrow {
  padding: 10px;
  font-size: 30px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 20px;
}
.react-calendar__tile abbr:hover {
  background: #f3f3f3;
  color: #212529;
}
.react-calendar__tile {
  width: 45px;
  height: 45px;
  padding: 5px;
}
.react-calendar__tile abbr {
  padding: 12px 15px;
  border-radius: 50px;
}
.react-calendar__tile--active abbr {
  background: white;
  color: #212529;
}
.react-calendar__tile:disabled abbr {
  background: transparent;
  color: #5e5e5e;
}

.MuiCircularProgress-root {
  width: 25px !important;
  height: 25px !important;
}
